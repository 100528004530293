// import { createEffect, attach, createStore } from "effector"
import { AxiosResponse } from "axios"
import { AuthRepository } from "ftl-auth-module"

//types
import {
  createUtilsRepository,
  createRepositoryInstance,
  createProfileRepository,
  createCountryRepository,
  createEntityRepository,
  BasicResponse,
} from "ftl-dashboards-core"
import { Brand, BrandRequest } from "../types/entities/brand"
import { CategoryTag, CategoryTagRequest } from "../types/entities/categoryTag"
import {
  Organization,
  OrganizationRequest,
} from "../types/entities/organization"
import { Person, PersonRequest } from "../types/entities/person"
import { Role, RoleRequest } from "../types/entities/role"
import { Tag, TagRequest } from "../types/entities/tag"
import { Report } from "../types/reports/report"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

export const apiAuthBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "http://localhost:8080"
export const apiCrmBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  : "http://localhost:8080"
export const apiAdminBaseUrl = process.env.REACT_APP_FTL_ADMIN_BASE_URL
  ? process.env.REACT_APP_FTL_ADMIN_BASE_URL
  : "http://localhost:8080"

export const baseCrmUrl = `${apiCrmBaseUrl}/api/${Versions.v1}`
export const baseAuthUrl = `${apiAuthBaseUrl}/api/${Versions.v1}`

export const repository = createRepositoryInstance({
  baseUrl: baseCrmUrl,
  tokenRefresh: () => AuthAPI.refresh(),
})

export const AuthAPI = new AuthRepository(
  repository,
  {
    signIn: `${apiAuthBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: `${apiAuthBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${apiAuthBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  }
)

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

//Person
const normalizePersonPayload = (
  payload: Person | Person<"PATCH">
): typeof payload => {
  const newPayload = {
    fullName: payload.fullName,
    birthday: payload.birthday,
    email: payload.email,
    gender: payload.gender,
    phone: payload.phone,
    avatarId: payload.avatarId,
    registerSource: payload.registerSource,
    referralPersonId: payload.referralPersonId,
    idType: payload.idType,
  }
  if ("registrationDate" in payload && payload.registrationDate) {
    return {
      ...newPayload,
      tagIds: payload.tagIds,
      socialLinkIds: payload.socialLinkIds,
      registrationDate: payload.registrationDate,
    }
  } else return newPayload
}

export const PersonAPI = Object.freeze({
  ...createEntityRepository<
    PersonRequest,
    Person<"GET">,
    Person,
    Person<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePersonPayload,
    basePath: "persons",
    utils: UtilsAPI,
  }),
  deleteAvatar: (id: string) => {
    return repository.delete(`persons/${id}/profile-image`)
  },
  delete: (id: string) => {
    return repository.delete(`persons/${id}`)
  },
  updateTags: (id: string, newTags: string[]) => {
    return repository.post(`persons/${id}/update-tags`, {
      tagIds: newTags,
    })
  },
})

//Tag
const normalizeTagPayload = (payload: Tag | Tag<"PATCH">): typeof payload => ({
  name: payload.name,
  description: payload.description,
  categoryTagId: payload.categoryTagId,
})

export const TagAPI = Object.freeze({
  ...createEntityRepository<TagRequest, Tag<"GET">, Tag, Tag<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeTagPayload,
    basePath: "tags",
    utils: UtilsAPI,
    defaultListParams: {
      offset: 0,
      limit: 1000,
      sortDirection: "DESC",
      sortName: "createdAt",
    },
  }),
  delete: (id: string) => {
    return repository.delete(`tags/${id}`)
  },
})

//CategoryTag
const normalizeCategoryTagPayload = (
  payload: CategoryTag | CategoryTag<"PATCH">
): typeof payload => ({
  name: payload.name,
  description: payload.description,
  parentCategoryTagId: payload.parentCategoryTagId,
})

export const CategoryTagAPI = Object.freeze({
  ...createEntityRepository<
    CategoryTagRequest,
    CategoryTag<"GET">,
    CategoryTag,
    CategoryTag<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeCategoryTagPayload,
    basePath: "category-tags",
    utils: UtilsAPI,
    defaultListParams: {
      offset: 0,
      limit: 1000,
      sortDirection: "DESC",
      sortName: "createdAt",
    },
  }),
  delete: (id: string) => {
    return repository.delete(`category-tags/${id}`)
  },
})

//Roles

export const RoleAPI = createEntityRepository<RoleRequest, Role>({
  repositoryInstance: repository,
  basePath: "roles",
  utils: UtilsAPI,
})

//Organization

const normalizeOrganizationPayload = (payload: Organization): Organization => ({
  activeStatus: payload.activeStatus,
  name: payload.name,
})

export const OrganizationAPI = createEntityRepository<
  OrganizationRequest,
  Organization<"GET">,
  Organization,
  Organization<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeOrganizationPayload,
  basePath: "organizations",
  utils: UtilsAPI,
})

//Brands

const normalizeBrandPayload = (payload: Brand): Brand => ({
  name: payload.name,
  activeStatus: payload.activeStatus,
  logoId: payload.logoId,
  organizationId: payload.organizationId,
})

export const BrandAPI = createEntityRepository<
  BrandRequest,
  Brand<"GET">,
  Brand,
  Brand<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeBrandPayload,
  basePath: "brands",
  utils: UtilsAPI,
})

//Reports
export const ReportsAPI = Object.freeze({
  getGrowthByMonth: (): Promise<AxiosResponse<BasicResponse<Report>>> => {
    return repository.get("reports/growth-per-month")
  },
  getPersonsByMonth: (): Promise<AxiosResponse<BasicResponse<Report>>> => {
    return repository.get(`reports/persons-by-month`)
  },
  getTagsRatio: (
    tagId: string
  ): Promise<AxiosResponse<BasicResponse<Report>>> => {
    return repository.get(`reports/ratio/tag?tagId=${tagId}`)
  },
})
