import { lazy, Suspense } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { FTLButton, FTLErrorPage } from "ftl-dashboards-ui-kit"
import { CountryAPI, PictureAPI, ProfileAPI, UtilsAPI } from "./repository"
import { PrivateRoute } from "ftl-dashboards-core"

//Profile
const ProfilePage = lazy(
  () => import("ftl-dashboards-templates/dist/templates/FTLProfilePage")
)

//Tags
const TagsList = lazy(() => import("./pages/Tags/List"))
const CategoryDetail = lazy(() => import("./pages/Tags/CategoryDetail"))
const TagDetail = lazy(() => import("./pages/Tags/TagDetail"))

//Persons
const PersonsList = lazy(() => import("./pages/Persons/List"))
const PersonDetail = lazy(() => import("./pages/Persons/Detail"))

//Graph
const PersonsByMonth = lazy(() => import("./pages/Reports/PersonsByMonth"))
const TagsReport = lazy(() => import("./pages/Reports/TagsReport"))

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/profile">
        <ProfilePage
          getProfile={ProfileAPI.get}
          editProfile={ProfileAPI.edit}
          getCountry={CountryAPI.getById}
          getCountries={CountryAPI.getList}
          getDefaultCountry={() => UtilsAPI.getDefault("countries")}
          deleteProfilePicture={ProfileAPI.deletePicture}
          changeProfilePassword={ProfileAPI.changePassword}
          postPicture={PictureAPI.post}
        />
      </PrivateRoute>
      <PrivateRoute exact path="/tags/:parentId?">
        <TagsList />
      </PrivateRoute>
      <PrivateRoute exact path="/tags/category/new">
        <CategoryDetail pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/tags/category/:id">
        <CategoryDetail pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/tags/detail/new">
        <TagDetail pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/tags/detail/:id">
        <TagDetail pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/persons">
        <PersonsList />
      </PrivateRoute>
      <PrivateRoute exact path="/persons/new">
        <PersonDetail pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/persons/:id">
        <PersonDetail pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/persons/total">
        <PersonsByMonth type="total" />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/persons/growth">
        <PersonsByMonth type="growth" />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/tags">
        <TagsReport />
      </PrivateRoute>
      <PrivateRoute path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>
    </Switch>
  )
}
