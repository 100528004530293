import { UIMain } from "ftl-dashboards-templates"
import { Routes } from "./Routes"
import {
  apiAuthBaseUrl,
  AuthAPI,
  CountryAPI,
  PictureAPI,
  ProfileAPI,
  UtilsAPI,
} from "./repository"
import {
  basicReducer,
  handleError,
  initialState,
  logOutAction,
  setProfileAction,
} from "ftl-dashboards-core"
import { createStore } from "redux"
import {
  FolderIcon,
  OrderServiceIcon,
  PeopleIcon,
} from "ftl-dashboards-ui-kit/dist/components/icons"
import { theme } from "ftl-dashboards-ui-kit"
import axios from "axios"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
const projectTitleStr = process.env.REACT_APP_FTL_PROJECT_TITLE
  ? process.env.REACT_APP_FTL_PROJECT_TITLE
  : "FTL Marketing ARM"

export const App = () => {
  return (
    <UIMain
      redirectOnAuthUrl="/tags"
      project={{
        title: projectTitleStr,
      }}
      AuthRepository={AuthAPI}
      StoreProviderProps={{
        store,
      }}
      theme={theme}
      BaseLayoutProps={{
        onMenuClick: async () => {
          if (!store.getState().globalStore.profile?.id)
            try {
              const profile = (await ProfileAPI.get()).data.result
              store.dispatch(setProfileAction(profile))
            } catch (error) {
              handleError({
                error,
                defaultError: "Произошла ошибка при получении данных профиля",
              })
            }
        },

        onSignOut: () => store.dispatch(logOutAction),
        sideMenuItems: [
          {
            to: "/tags",
            label: "Теги",
            icon: <FolderIcon />,
          },
          {
            to: "/persons",
            label: "Гости",
            icon: <PeopleIcon />,
          },
          {
            items: [
              {
                label: "Всего гостей",
                to: "/reports/persons/total",
              },
              {
                label: "Новые гости",
                to: "/reports/persons/growth",
              },
              {
                label: "Отчеты по тегам",
                to: "/reports/tags",
              },
            ],
            to: "/reports/",
            label: "Отчеты",
            icon: <OrderServiceIcon />,
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
